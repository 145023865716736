import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { InfoHint } from 'components/common'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { CloseIconButton } from 'ui/inputs/close-icon-button'
import { LinkButton } from 'ui/navigation'

export type DialogContentProps = {
  title: string
  subtitle?: string | ReactNode
  headerEndComponent?: JSX.Element
  leftSideBar?: JSX.Element
  rightSideBar?: JSX.Element
  children: React.ReactNode
  isChildrenWithContainer?: boolean
  onConfirm?: () => void
  onDeny?: () => void
  confirmText?: string
  denyText?: string
  isConfirmDisabled?: boolean
  tooltipOnDisabledConfirm?: string
  disableOverflow?: boolean
  variant?: 'success' | 'danger'
  disableContentPadding?: boolean
  wrapWithForm?: boolean
  externalFormId?: string
  disableHeaderPadding?: boolean
  isActionLoading?: boolean
  hint?: string
  dataCyConfirmButton?: string
  dataCyCancelButton?: string
  isWithCloseIconButton?: boolean
  linkButton?: {
    linkToPath: string
    linkTitle: string
    linkTarget?: string
    linkVariant?: 'success' | 'danger'
  }
}

export const DialogContent = ({
  title,
  subtitle,
  headerEndComponent,
  leftSideBar,
  rightSideBar,
  disableContentPadding = false,
  denyText,
  onDeny,
  wrapWithForm = false,
  externalFormId,
  disableHeaderPadding = false,
  disableOverflow,
  variant = 'success',
  isConfirmDisabled,
  tooltipOnDisabledConfirm,
  confirmText,
  onConfirm,
  children,
  isChildrenWithContainer = true,
  isActionLoading = false,
  isWithCloseIconButton = true,
  hint,
  linkButton,
}: DialogContentProps) => {
  const { t } = useTranslation()

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: ' auto',
        //maximum size of dialog container to have vertical margins
        maxHeight: 'calc(100% - 64px)',
        flex: 1,
      }}
    >
      <Box
        px={2}
        py={1.5}
        mb={disableHeaderPadding ? 0 : 2.5}
        sx={{
          flex: '0 0 auto',
        }}
      >
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box display="flex">
            <Typography variant="h2">{title}</Typography>
            {hint && <InfoHint hint={hint} />}
          </Box>

          {isWithCloseIconButton && (
            <Box>
              <CloseIconButton onClose={onDeny} />
            </Box>
          )}
        </Stack>
        {subtitle && (
          <Typography
            color="text.secondary"
            mt={2}
            mb={headerEndComponent ? 2 : 0}
          >
            {subtitle}
          </Typography>
        )}
        {headerEndComponent}
      </Box>
      <Stack
        direction="row"
        flex="auto"
        height="100%"
        //maximum size of dialog container to have vertical margins
        maxHeight="calc(100vh - 250px)"
      >
        {leftSideBar}
        {isChildrenWithContainer ? (
          <Box
            flex={1}
            overflow={disableOverflow ? 'visible' : 'auto'}
            sx={{
              //maximum size of dialog container to have vertical margins
              maxHeight: 'calc(100vh - 250px)',
            }}
            border="none"
          >
            <Box p={disableContentPadding ? 0 : 2} pt={0} border="none">
              {typeof children === 'string' ? (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  minHeight={60}
                >
                  {children}
                </Typography>
              ) : (
                children
              )}
            </Box>
          </Box>
        ) : (
          children
        )}

        {rightSideBar}
      </Stack>
      <Box
        sx={{
          bgcolor: '#FFF',
          p: 2,
          pt: 1.5,
          display: 'flex',
          WebkitBoxPack: 'end',
          flex: '0 0 auto',
          webkitBoxPack: 'end',
          webkitBoxAlign: 'center',
        }}
      >
        {onDeny && (
          <Button
            variant="contained"
            color="greyBlue"
            onClick={onDeny}
            size="large"
          >
            {denyText ?? t('common.cancel')}
          </Button>
        )}
        <Box ml="auto">
          {linkButton && (
            <LinkButton
              target={linkButton.linkTarget ?? '_self'}
              to={linkButton.linkToPath}
              sx={{ mr: 2 }}
              color={linkButton.linkVariant === 'danger' ? 'error' : 'primary'}
            >
              {linkButton.linkTitle ?? t('common.open_link')}
            </LinkButton>
          )}
          {(onConfirm || externalFormId) && (
            <Tooltip
              placement="top-end"
              title={
                isConfirmDisabled && tooltipOnDisabledConfirm
                  ? tooltipOnDisabledConfirm
                  : ''
              }
            >
              <Button
                type={wrapWithForm || externalFormId ? 'submit' : 'button'}
                color={variant === 'danger' ? 'error' : 'primary'}
                onClick={wrapWithForm ? undefined : onConfirm}
                disabled={isConfirmDisabled}
                loading={isActionLoading}
                form={externalFormId}
                size="large"
              >
                {confirmText ?? t('confirms.confirm')}
              </Button>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  )

  return wrapWithForm ? (
    <Box
      component="form"
      noValidate
      onSubmit={event => {
        event.stopPropagation()
        event.preventDefault()
        onConfirm && onConfirm()
      }}
      sx={{
        flex: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {content}
    </Box>
  ) : (
    content
  )
}
