import { Box, Stack, Typography } from '@mui/material'
import { getGradientColors } from 'lib/app-helpers/reports/get-gradient-colors'
import { ReactNode } from 'react'

type Props = Readonly<{
  title: string
  description: string
  items: Array<
    | {
        label: string
        value?: string
        icon?: ReactNode
      }
    | {
        label: undefined
        value: string
        icon: ReactNode
      }
  >
  actions?: ReactNode
}>

const TITLE_MAX_WIDTH = '320px'
const ITEM_MAX_WIDTH = '200px'
const ITEM_ICON_CONTAINER_SIZE = '24px'
const ITEM_ICON_SIZE = '16px'

export const SettingsDetailsRow = ({
  title,
  description,
  items,
  actions,
}: Props) => {
  const gradientColors = getGradientColors(items.length, 'VIOLET')

  return (
    <Box display="flex">
      <Box maxWidth={TITLE_MAX_WIDTH} width="100%" flexShrink={0} mr="60px">
        <Typography variant="body1" color="mineShaft.main" mb="4px">
          {title}
        </Typography>
        <Typography variant="body2" color="mineShaft.800">
          {description}
        </Typography>
      </Box>

      <Box flex={1}>
        <Stack
          direction="row"
          gap="24px"
          flexWrap="wrap"
          maxWidth={1200}
          width="100%"
        >
          {items.map((item, index) => {
            if (item?.label) {
              if (!item.value) {
                return null
              }

              return (
                <Box
                  key={item.label}
                  maxWidth={ITEM_MAX_WIDTH}
                  width="100%"
                  flexShrink={0}
                >
                  <Typography
                    variant="caption"
                    color="mineShaft.900"
                    fontWeight={500}
                    mb="6px"
                  >
                    {item.label}
                  </Typography>
                  <Typography variant="body1" color="mineShaft.main">
                    {item.value}
                  </Typography>
                </Box>
              )
            }

            return (
              <Box
                key={item.value}
                maxWidth={ITEM_MAX_WIDTH}
                flexShrink={0}
                width="100%"
              >
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',

                      width: ITEM_ICON_CONTAINER_SIZE,
                      height: ITEM_ICON_CONTAINER_SIZE,
                      mr: 1,
                      flexShrink: 0,

                      bgcolor: gradientColors[index],
                      color: 'white',

                      borderRadius: '4px',

                      svg: {
                        fontSize: ITEM_ICON_SIZE,
                      },
                    }}
                  >
                    {item.icon}
                  </Box>
                  <Typography variant="body1" color="mineShaft.main">
                    {item.value}
                  </Typography>
                </Box>
              </Box>
            )
          })}
        </Stack>
      </Box>

      <Box>{actions}</Box>
    </Box>
  )
}
