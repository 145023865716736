import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSettingsUsersAndPermissions = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M13.3327 2.8898C14.5674 3.50343 15.416 4.77762 15.416 6.25C15.416 7.72238 14.5674 8.99657 13.3327 9.6102M14.9993 13.972C16.2589 14.5419 17.3931 15.4708 18.3327 16.6667M1.66602 16.6667C3.28809 14.6021 5.49033 13.3333 7.91602 13.3333C10.3417 13.3333 12.5439 14.6021 14.166 16.6667M11.666 6.25C11.666 8.32107 9.98708 10 7.91602 10C5.84495 10 4.16602 8.32107 4.16602 6.25C4.16602 4.17893 5.84495 2.5 7.91602 2.5C9.98708 2.5 11.666 4.17893 11.666 6.25Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
