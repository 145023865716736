import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getContactDetails, getContactHistory, qk } from 'api'
import { paths } from 'app/paths'
import { useScopes } from 'app/scopes'
import {
  IconChevronLeft,
  IconChevronRight,
  IconLevelsCritical,
} from 'assets/icons'
import {
  AddContactsToTalentsPool,
  ContactBadgeCollection,
  InviteToInterviewButton,
} from 'components/contacts'
import { ErrorTemplateSmall } from 'components/templates'
import { useFormatInTimeZone } from 'lib/app-helpers'
import { renderAllQueriesResult } from 'lib/react-query-utils'
import { useBoolean } from 'lib/react-utils'
import { CreateActivityDialog } from 'pages/activities/create'
import { SocialContactItems } from 'pages/contacts/profile/details/_sections'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { PurpleFadingGradientContainer } from 'ui/containers'
import { Avatar, Timeline, TimelineItem } from 'ui/data'
import { Button } from 'ui/inputs/button'
import { CloseIconButton } from 'ui/inputs/close-icon-button'
import { IconButton } from 'ui/inputs/icon-button'
import { Link } from 'ui/navigation'
import { TruncateWithTooltip } from 'ui/utils'

import { CircleDivider } from './work-experience/circle-divider'

export type Props = {
  contactId: string
  interviewPositionName?: string
  jobId?: string
  onOpenDialog?: () => void
  onCloseDialog?: () => void
  onClose?: () => void
  contactIds?: Array<string>
  setPreviewId?: (previewId: string) => void
}

export const ContactPreview = (props: Props) => {
  const { t } = useTranslation()

  const { canManageActivity, canManageTalentsPool } = useScopes()

  const isNewActivityOpen = useBoolean()

  const $contactDetails = useQuery(
    qk.contacts.details.toKeyWithArgs({ contactId: props.contactId }),
    () => getContactDetails({ contactId: props.contactId }),
  )

  const args = {
    contactId: props.contactId,
    pagination: {
      page: 0,
      pageSize: 4,
    },
  }

  const $contactLogs = useQuery(
    qk.contacts.logs.toKeyWithArgs(args),
    () => getContactHistory(args),
    { keepPreviousData: true },
  )

  const { formatDate, format } = useFormatInTimeZone()

  return (
    <>
      {renderAllQueriesResult([$contactDetails, $contactLogs], {
        error: error => <ErrorTemplateSmall error={error} />,
        success: ([contactDetails, contactLogs]) => {
          return (
            <Box display="flex" flexDirection="column" height="100%">
              <PurpleFadingGradientContainer>
                <Stack spacing={1} p={2}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {props.contactIds && props.setPreviewId && (
                      <ContactsNavigation
                        contactIds={props.contactIds}
                        contactId={props.contactId}
                        setPreviewId={props.setPreviewId}
                      />
                    )}

                    <Box>
                      <CloseIconButton onClose={props.onClose} />
                    </Box>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar size={60} src={contactDetails.imageUri} />

                    <Stack spacing={0.5}>
                      <TruncateWithTooltip variant="h3" maxWidth={200}>
                        <Link
                          to={generatePath(paths.contactDetails, {
                            contactId: props.contactId,
                          })}
                          maxWidth={200}
                        >
                          {contactDetails.name}
                        </Link>
                      </TruncateWithTooltip>

                      <Typography color="text.disabled">
                        {contactDetails.position.name} |{' '}
                        {contactDetails.qualificationLevel?.name}
                      </Typography>
                    </Stack>
                  </Stack>

                  <ContactBadgeCollection contactId={props.contactId} />
                </Stack>
              </PurpleFadingGradientContainer>

              <Stack
                spacing={2}
                divider={<Divider />}
                py={2}
                flexGrow={1}
                sx={{ overflowY: 'auto' }}
              >
                <Stack spacing={2} px={2}>
                  <SocialContactItems
                    contactInfos={contactDetails.contactInfos}
                  />
                </Stack>

                <Box
                  sx={{
                    b: {
                      fontWeight: 500,
                    },
                    px: 2,
                  }}
                >
                  <Timeline position="right">
                    {contactLogs.rows.map(item => {
                      return (
                        <TimelineItem key={item.id}>
                          <Stack spacing={1}>
                            <Stack
                              direction="row"
                              columnGap={2}
                              rowGap={1}
                              alignItems="center"
                              flexWrap="wrap"
                            >
                              <CircleDivider color="#717171">
                                <Typography
                                  sx={{ color: '#717171' }}
                                  variant="caption"
                                  whiteSpace="nowrap"
                                >
                                  {formatDate(item.createdAt)}
                                </Typography>
                                <Typography
                                  color="mineShaft.500"
                                  ml={0.5}
                                  variant="caption"
                                  whiteSpace="nowrap"
                                  sx={{ color: '#717171' }}
                                >
                                  {format('pp')(item.createdAt)}
                                </Typography>
                              </CircleDivider>

                              <Box display="flex" alignItems="center">
                                <IconLevelsCritical
                                  sx={{
                                    color: '#4D00F0',
                                    fontSize: '14px',
                                  }}
                                />

                                <Typography
                                  ml={0.25}
                                  variant="caption"
                                  fontWeight={500}
                                  whiteSpace="nowrap"
                                  sx={{ color: 'mineShaft.main' }}
                                >
                                  {item.subject}
                                </Typography>
                              </Box>
                            </Stack>

                            <Box maxWidth="300px">
                              <Typography
                                variant="body2"
                                color="text.primary"
                                dangerouslySetInnerHTML={{
                                  __html: item.message,
                                }}
                                sx={{ wordWrap: 'break-word' }}
                              />
                            </Box>
                          </Stack>
                        </TimelineItem>
                      )
                    })}
                  </Timeline>
                </Box>
              </Stack>

              <Divider />

              <Stack gap={2} p={2} sx={{ flexShrink: 0 }}>
                {canManageTalentsPool && (
                  <AddContactsToTalentsPool
                    buttonProps={{
                      size: 'large',
                      variant: 'contained',
                    }}
                    contactsIds={[props.contactId]}
                    onOpenDialog={props.onOpenDialog}
                    onCloseDialog={props.onCloseDialog}
                  />
                )}

                <Stack direction="row" spacing={2}>
                  {canManageActivity && (
                    <>
                      <Button
                        variant="outlined"
                        color="greyBlue"
                        onClick={() => {
                          isNewActivityOpen.setTrue()
                          if (props.onOpenDialog) props.onOpenDialog()
                        }}
                        size="large"
                        fullWidth
                      >
                        {t('common.new_activity')}
                      </Button>

                      <CreateActivityDialog
                        isOpen={isNewActivityOpen.isTrue}
                        onClose={() => {
                          isNewActivityOpen.setFalse()
                          if (props.onCloseDialog) props.onCloseDialog()
                        }}
                        contactId={props.contactId}
                      />
                    </>
                  )}

                  <InviteToInterviewButton
                    candidateId={contactDetails.candidateProfileId}
                    positionName={props.interviewPositionName}
                    isContactHasEmail={
                      !!contactDetails?.contactInfos.some(
                        ({ type }) => type === 'EMAIL',
                      )
                    }
                    jobId={props.jobId}
                    renderButton={({
                      isDisabled,
                      disabledTooltipTitle,
                      onClick,
                    }) => {
                      return (
                        <Tooltip
                          title={isDisabled ? disabledTooltipTitle : null}
                        >
                          <Box>
                            <Button
                              onClick={onClick}
                              disabled={isDisabled}
                              variant="outlined"
                              color="greyBlue"
                              size="large"
                              fullWidth
                            >
                              {t('common.schedule_interview')}
                            </Button>
                          </Box>
                        </Tooltip>
                      )
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
          )
        },
      })}
    </>
  )
}

type ContactsNavigationProps = Readonly<{
  contactIds: Array<string>
  contactId: string
  setPreviewId: (contactId: string) => void
}>

const ContactsNavigation = ({
  contactIds,
  contactId,
  setPreviewId,
}: ContactsNavigationProps) => {
  if (contactIds.length === 0) {
    return null
  }

  const currentContactIndex = contactIds.indexOf(contactId)

  const prevContactIndex = contactIds[currentContactIndex - 1]
  const nextContactIndex = contactIds[currentContactIndex + 1]

  return (
    <Stack
      direction="row"
      divider={
        <Divider
          orientation="vertical"
          sx={{
            width: '1px',
            height: '24px',
            bgcolor: theme => theme.palette.mineShaft[200],
          }}
        />
      }
      bgcolor={theme => theme.palette.mineShaft.contrastText}
      border={theme => `1px solid ${theme.palette.mineShaft[200]}`}
      borderRadius="35px"
    >
      <IconButton
        sx={{
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
          width: '24px',
          height: '24px',
        }}
        disabled={!prevContactIndex}
        onClick={() => setPreviewId(prevContactIndex!)}
      >
        <IconChevronLeft
          sx={{
            fontSize: '12px',
            color: !prevContactIndex ? 'inherit' : 'mineShaft.main',
          }}
        />
      </IconButton>

      <IconButton
        sx={{
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          width: '24px',
          height: '24px',
        }}
        disabled={!nextContactIndex}
        onClick={() => setPreviewId(nextContactIndex!)}
      >
        <IconChevronRight
          sx={{
            fontSize: '12px',
            color: !nextContactIndex ? 'inherit' : 'mineShaft.main',
          }}
        />
      </IconButton>
    </Stack>
  )
}
