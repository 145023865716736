import { TCompanyPosition } from 'api/company'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TFilterHistoryValues = t.partial({
  levels: t.array(
    t.strict({
      code: t.string,
      name: t.string,
    }),
  ),
  from: DateFromISOString,
  to: DateFromISOString,
  skills: t.array(
    t.strict({
      skillId: t.string,
      skillName: t.string,
    }),
  ),
  sources: t.array(t.strict({ id: t.string, name: t.string })),
  talentPools: t.array(t.strict({ talentPoolId: t.string, name: t.string })),
  positions: t.array(TCompanyPosition),
  location: t.partial({
    country: t.string,
    city: t.string,
  }),
})

export type FilterHistoryValues = t.TypeOf<typeof TFilterHistoryValues>

export const TFilterCriteria = t.intersection([
  t.strict({
    name: t.string,
  }),
  TFilterHistoryValues,
])

export type FilterCriteria = t.TypeOf<typeof TFilterCriteria>

export const TFilterHistoryRow = t.strict({
  filterCriteria: TFilterCriteria,
  filterHistoryId: t.string,
  createdAt: DateFromISOString,
})

export const TSavedFilterRow = t.strict({
  filterCriteria: TFilterCriteria,
  filterId: t.string,
  createdAt: DateFromISOString,
})

export const TFiltersName = t.strict({ filterName: t.string })
