import { ContactCompleteness } from 'components/contacts'
import { BadgeCollection } from 'ui/data'

import { TalentsPullsBadge } from '../../pages/contacts/profile/contact-toolbar/_components/talents-pulls-badge'

type Props = Readonly<{
  contactId: string
}>

export const ContactBadgeCollection = ({ contactId }: Props) => {
  return (
    <BadgeCollection>
      {/* TODO: NOT IMPLEMENTED */}
      {/* <AddToFavoritesBadge /> */}

      <TalentsPullsBadge contactId={contactId} />

      <ContactCompleteness contactId={contactId} />
    </BadgeCollection>
  )
}
