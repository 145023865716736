import { RecruiterId } from 'api/auth'
import { ContactId } from 'api/contacts'
import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import { copyTimeToDate, toServerDateTime } from 'lib/js-utils'
import {
  decodeJson,
  decodeJsonWithTotal,
  get,
  patch,
  post,
  put,
} from 'lib/request'

import {
  ActivityChannel,
  ActivityStatus,
  ActivityType,
  TActivity,
  TContactActivityList,
  TWeeklyActivity,
} from './activities.codecs'

export type ActivityId = {
  activityId: string
}

type ActivityCommonInput = {
  type: ActivityType
  channel?: ActivityChannel
  date: Date
  startTime: Date
  endTime?: Date
  jobId: string
  contact: string
  notes: string
  link?: string
}

const adjustActivityCommonInput = (input: ActivityCommonInput) => {
  return {
    type: input.type,
    ...(input.type === 'FOLLOW_UP' && { channel: input.channel }),
    startDate: toServerDateTime(copyTimeToDate(input.date, input.startTime)),
    ...(input.type === 'INTERVIEW' &&
      input.endTime && {
        endDate: toServerDateTime(copyTimeToDate(input.date, input.endTime)),
      }),
    ...(input.type === 'INTERVIEW' && { link: input.link }),
    jobId: input.jobId || undefined,
    contact: input.contact,
    notes: input.notes || undefined,
  }
}

export const createActivity = async ({
  timeZone,
  ...input
}: ActivityCommonInput & { timeZone: string }) => {
  return await post('api/activities', {
    body: adjustActivityCommonInput(input),
    headers: new Headers({
      Timezone: timeZone,
    }),
  })
}

export type GetActivitiesListInput = {
  filters: {
    from: Date
    to: Date
    status?: string
    channel?: string
    jobs?: Array<string>
  }
  pagination: ListPagination
  timeZone: string
  order?: ListOrder
}

export const getActivitiesList = async ({
  filters,
  order,
  pagination,
  timeZone,
}: GetActivitiesListInput) => {
  const params = createListSearchParams({ pagination, order })

  const bodyFilters: Array<{
    filterType: string
    [key: string]: any
  }> = [
    {
      filterType: 'startDateFilter',
      from: toServerDateTime(filters.from),
      to: toServerDateTime(filters.to),
    },
  ]

  if (filters.status) {
    bodyFilters.push({
      filterType: 'statusFilter',
      status: filters.status,
    })
  }

  if (filters.channel) {
    bodyFilters.push({
      filterType: 'channelFilter',
      channel: filters.channel,
    })
  }

  if (filters.jobs && filters.jobs.length > 0) {
    bodyFilters.push({
      filterType: 'jobFilter',
      jobIds: filters.jobs,
    })
  }

  return pipe(
    await post('api/activities/list', {
      body: { filters: bodyFilters },
      query: params,
      headers: new Headers({
        Timezone: timeZone,
      }),
    }),
    decodeJsonWithTotal(TActivity, pagination),
  )
}

export type GetWeeklyActivitiesInput = {
  status: string
  order?: ListOrder
  pagination: ListPagination
} & RecruiterId

export const getWeeklyActivities = async ({
  status,
  recruiterId,
  order,
  pagination,
}: GetWeeklyActivitiesInput) => {
  const params = createListSearchParams({ pagination, order })

  params.set('status', status)

  if (recruiterId !== '') {
    params.set('recruiterId', recruiterId)
  }

  return pipe(
    await get('api/activities/activities-per-week', {
      query: params,
    }),
    decodeJsonWithTotal(TWeeklyActivity, pagination),
  )
}

export type GetContactActivitiesInput = ContactId & {
  activityTypes?: Array<ActivityType>
}

export const getContactActivities = async ({
  contactId,
  activityTypes = ['FOLLOW_UP', 'INTERVIEW'],
}: GetContactActivitiesInput) => {
  const query = new URLSearchParams()

  for (const type of activityTypes) query.append('activityTypes', type)

  return pipe(
    await get('api/activities/candidates/:contactId', {
      params: { contactId },
      query,
    }),
    decodeJson(TContactActivityList),
  )
}

export const updateActivity = async ({
  activityId,
  status,
  timeZone,
  ...common
}: ActivityCommonInput &
  ActivityId & {
    status: ActivityStatus
    timeZone: string
  }) => {
  return await put('api/activities/:activityId', {
    params: { activityId },
    headers: new Headers({
      Timezone: timeZone,
    }),
    body: {
      ...adjustActivityCommonInput(common),
      /**
       * Activity status cannot be changed to EXPIRED or UPCOMING manually
       * So in case user edits such activity, status must be replaced to OPEN
       */
      status: status === 'UPCOMING' || status === 'EXPIRED' ? 'OPEN' : status,
    },
  })
}

export const changeActivityStatus = async ({ activityId }: ActivityId) => {
  return await patch('api/activities/:activityId', {
    params: { activityId },
    query: new URLSearchParams({ status: 'DONE' }),
  })
}
