import { InviteToInterviewWindow } from './invite-to-interview-window'

type Props = Readonly<{
  isOpen: boolean
  onClose: () => void
  candidateId: string
  positionName?: string
  invitationUrl: string | null
  onSend?: () => void
}>

export const InviteToInterviewRenderer = ({
  isOpen,
  candidateId,
  positionName,
  invitationUrl,
  onClose,
  onSend,
}: Props) => {
  return (
    <>
      {isOpen && invitationUrl && (
        <InviteToInterviewWindow
          isOpen={isOpen}
          onClose={onClose}
          onSend={onSend}
          candidateId={candidateId}
          positionName={positionName}
          invitationUrl={invitationUrl}
        />
      )}
    </>
  )
}
