import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSettingsPersonalData = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M4.4296 16.1985C4.93653 15.0042 6.12012 14.1665 7.49935 14.1665H12.4993C13.8786 14.1665 15.0622 15.0042 15.5691 16.1985M13.3327 7.9165C13.3327 9.75745 11.8403 11.2498 9.99935 11.2498C8.1584 11.2498 6.66602 9.75745 6.66602 7.9165C6.66602 6.07555 8.1584 4.58317 9.99935 4.58317C11.8403 4.58317 13.3327 6.07555 13.3327 7.9165ZM18.3327 9.99984C18.3327 14.6022 14.6017 18.3332 9.99935 18.3332C5.39698 18.3332 1.66602 14.6022 1.66602 9.99984C1.66602 5.39746 5.39698 1.6665 9.99935 1.6665C14.6017 1.6665 18.3327 5.39746 18.3327 9.99984Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
