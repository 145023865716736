import { Box, ClickAwayListener, SxProps } from '@mui/material'
import { ThemeColor } from 'app/mui-theme'
import { IconClose } from 'assets/icons'
import { useBoolean } from 'lib/react-utils'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { IconButton } from 'ui/inputs/icon-button'
import { TextField } from 'ui/inputs/text-field'
import { TruncateWithTooltip } from 'ui/utils'

type Props = {
  children: string
  icon?: React.ReactNode
  background?: ThemeColor
  color?: string
  /** adds clear button in the end of the badge with onRemove listener */
  onRemove?: () => void
  /**
   * makes the badge transform into input on double click
   * calls onChange after clicking outside or pressing Enter
   */
  onChange?: (value: string) => void
  /** When used with icon leaves left and right paddings equal */
  disableIconShift?: boolean
  error?: boolean
  isDisabled?: boolean
  sx?: SxProps
}

export const Badge = forwardRef<HTMLSpanElement, Props>(
  (
    {
      icon,
      children,
      onRemove,
      onChange,
      background,
      disableIconShift = false,
      color = 'text.primary',
      error,
      isDisabled = false,
      sx,
      ...rest // needed to be properly wrapped with a tooltip
    }: Props,
    ref,
  ) => {
    const isEditing = useBoolean(false)
    const [value, setValue] = useState(children)

    const handleChange = useCallback(() => {
      isEditing.setFalse()

      if (onChange) {
        onChange(value)
      }
    }, [isEditing, onChange, value])

    useEffect(() => {
      const handleKeydown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          handleChange()
        }
      }

      const removeListener = () => {
        document.removeEventListener('keydown', handleKeydown)
      }

      if (isEditing.isTrue) {
        document.addEventListener('keydown', handleKeydown)
      } else {
        removeListener()
      }

      return removeListener
    }, [isEditing.isTrue, handleChange])

    if (isEditing.isTrue) {
      return (
        <ClickAwayListener onClickAway={handleChange}>
          <TextField
            small
            error={error}
            autoFocus
            value={value}
            onChange={setValue}
          />
        </ClickAwayListener>
      )
    }

    return (
      <Box
        {...rest}
        ref={ref}
        color={color}
        onDoubleClick={onChange && !isDisabled ? isEditing.setTrue : undefined}
        sx={{
          height: '24px',
          bgcolor: background ?? 'badge.main',
          pr: 1.5,
          pl: icon && !disableIconShift ? 1 : 1.5,
          borderRadius: '2px',
          py: '8px',
          display: 'inline-flex',
          alignItems: 'center',
          maxWidth: '580px',
          ...(error && {
            bgcolor: '#FEE6E7',
          }),
          ...sx,
        }}
      >
        {icon && (
          <Box display="flex" mr={0.5}>
            {icon}
          </Box>
        )}

        <TruncateWithTooltip
          variant="body2"
          isShowTooltip={!error}
          color="#1A1A1A"
        >
          {children}
        </TruncateWithTooltip>

        {onRemove && !isDisabled && (
          <IconButton
            disablePadding
            onClick={onRemove}
            sx={{ ml: 0.5, mr: -0.5 }}
          >
            <IconClose sx={{ fontSize: '18px', color }} />
          </IconButton>
        )}
      </Box>
    )
  },
)
