import { Box, SxProps, useTheme } from '@mui/material'
import { IconStars03 } from 'assets/icons'
import { ReactNode } from 'react'

type Props = Readonly<{
  children?: ReactNode
  sx?: SxProps
}>

export const StarlitGradientContainer = ({ children, sx }: Props) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        background: `linear-gradient(89.96deg, ${theme.palette.flushOrange[300]} 0.03%, #D18FF3 98.84%)`,
        minHeight: 160,
        ...sx,
      }}
    >
      {children}

      <IconStars03
        sx={{
          position: 'absolute',
          left: 5,
          top: -10,
          fontSize: 90,
          color: '#FFFFFF',
          opacity: 0.2,
        }}
      />
      <IconStars03
        sx={{
          position: 'absolute',
          right: '35%',
          top: -20,
          fontSize: 180,
          color: '#FFFFFF',
          opacity: 0.2,
        }}
      />
    </Box>
  )
}
