import { createTheme } from '@mui/material'

const inter = 'Inter, sans-serif'

export const scrollBarListStyles = {
  '&::-webkit-scrollbar': {
    width: '12px',
    marginLeft: '12px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#9A7EF0',
    borderRadius: '0px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#FFFFFF',
    border: '4px solid #9A7EF0',
    width: '4px',
    backgroundClip: 'content-box',
  },
}

export const MAX_FORM_CONTENT_WIDTH = '560px'
export const MAX_DETAILS_CONTENT_WIDTH = '654px'
export const APP_LAYOUT_X_PADDING = { xs: '32px' }

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1280,
      lg: 1440,
      xl: 1600,
      xxl: 1920,
    },
  },
  palette: {
    primary: {
      '100': '#F1EDFD',
      '200': '#E2DAFB',
      '300': '#D4C8F9',
      '400': '#C5B5F7',
      '500': '#B7A3F5',
      '600': '#A891F2',
      '700': '#9A7EF0',
      '800': '#8B6CEE',
      '900': '#7D59EC',
      main: '#6E47EA',
    },
    success: {
      main: '#008D6E',
    },
    text: {
      primary: '#2F2F2F',
      secondary: '#4D5678',
      disabled: '#9EA3B6',
    },
    background: {
      default: '#F9FAFC',
    },
    divider: '#EAEAEA',
    error: {
      main: '#D42828',
    },
    red: {
      '100': '#FBEAEA',
      '200': '#F6D4D4',
      '300': '#F2BFBF',
      '400': '#EEA9A9',
      '500': '#EA9494',
      '600': '#E57E7E',
      '700': '#E16969',
      '800': '#DD5353',
      '900': '#D83E3E',
      main: '#D42828',
    },
    info: {
      main: '#1F68FA',
    },
    warning: {
      main: '#FBA930',
    },
    badge: {
      main: '#EADBF9',
    },
    greyBlue: {
      light: '#9EA3B6',
      main: '#4D5678',
    },
    blue: {
      main: '#008BF0',
      contrastText: '#FFF',
    },
    brandOrange: {
      main: '#F47D66',
      contrastText: '#FFF',
    },
    orange: {
      main: '#FE8218',
      contrastText: '#FFF',
    },
    mineShaft: {
      '100': '#EAEAEA',
      '200': '#D5D5D5',
      '300': '#C1C1C1',
      '400': '#ACACAC',
      '500': '#979797',
      '600': '#828282',
      '700': '#6D6D6D',
      '800': '#595959',
      '900': '#444444',
      contrastText: '#F5F5F5',
      main: '#2F2F2F',
    },
    navyBlue: {
      '100': '#E6E6F2',
      '200': '#CCCCE6',
      '300': '#B3B3D9',
      '400': '#9999CC',
      '500': '#8080C0',
      '600': '#6666B3',
      '700': '#4D4DA6',
      '800': '#333399',
      '900': '#1A1A8D',
      main: '#000080',
    },
    mediumPurple: {
      '100': '#F5EDFC',
      '200': '#EADBF9',
      '300': '#E0C9F5',
      '400': '#D6B7F2',
      '500': '#CCA5EF',
      '600': '#C193EC',
      '700': '#B781E9',
      '800': '#AD6FE5',
      '900': '#A25DE2',
      main: '#984BDF',
    },
    jewel: {
      '100': '#E7EEEB',
      '200': '#CFDDD6',
      '300': '#B7CCC2',
      '400': '#9FBBAD',
      '500': '#87AA99',
      '600': '#6E9885',
      '700': '#568770',
      '800': '#3E765C',
      '900': '#266547',
      main: '#0E5433',
    },
    jade: {
      '100': '#E6F7F0',
      '200': '#CCEFE0',
      '300': '#B3E7D1',
      '400': '#99DFC1',
      '500': '#80D8B2',
      '600': '#66D0A3',
      '700': '#4DC893',
      '800': '#33C084',
      '900': '#1AB874',
      main: '#00B065',
    },
    cerulean: {
      '100': '#E6F4FC',
      '200': '#CDE9F9',
      '300': '#B4DEF6',
      '400': '#9BD3F3',
      '500': '#82C9F1',
      '600': '#69BEEE',
      '700': '#50B3EB',
      '800': '#37A8E8',
      '900': '#1E9DE5',
      main: '#0592E2',
    },
    selectiveYellow: {
      '100': '#FEF8E6',
      '200': '#FDF0CC',
      '300': '#FCE9B3',
      '400': '#FBE199',
      '500': '#FADA80',
      '600': '#F9D267',
      '700': '#F8CB4D',
      '800': '#F7C334',
      '900': '#F6BC1A',
      main: '#F5B401',
    },
    flushOrange: {
      '100': '#FFF3E6',
      '200': '#FFE6CC',
      '300': '#FFDAB3',
      '400': '#FFCD99',
      '500': '#FFC180',
      '600': '#FFB466',
      '700': '#FFA84D',
      '800': '#FF9B33',
      '900': '#FF8F1A',
      main: '#FF8200',
    },

    alabaster: '#F8F8F8',
    mischka: '#D8DAE2',
  },

  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      variants: [
        {
          props: { variant: 'elevation' },
          style: {
            boxShadow:
              '0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(50, 50, 105, 0.16)',
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },

  typography: {
    fontFamily: 'Inter, sans-serif',
  },
})

const { pxToRem } = theme.typography

theme.typography = {
  ...theme.typography,

  // Inter 28 Semi bold
  h1: {
    fontFamily: inter,
    fontSize: pxToRem(28),
    lineHeight: pxToRem(33.89),
    fontWeight: 600,
  },

  // Inter 22 Semi bold
  h2: {
    fontFamily: inter,
    fontSize: pxToRem(22),
    lineHeight: pxToRem(26.63),
    fontWeight: 600,
  },

  // Inter 18 Semi bold
  h3: {
    fontFamily: inter,
    fontSize: pxToRem(18),
    lineHeight: pxToRem(21.78),
    fontWeight: 600,
  },

  // Inter 16 Semi bold
  h4: {
    fontFamily: inter,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(19.36),
    fontWeight: 600,
  },

  // Inter 16 Medium
  h5: {
    fontFamily: inter,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(19.36),
    fontWeight: 500,
  },

  // Inter 16 Regular
  h6: {
    fontFamily: inter,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(19.36),
    fontWeight: 400,
  },

  // Inter 14 Medium
  body1: {
    fontFamily: inter,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16.94),
    fontWeight: 500,
    letterSpacing: '0.004em',
  },

  // Inter 14 Regular
  body2: {
    fontFamily: inter,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16.94),
    fontWeight: 400,
    letterSpacing: '0.004em',
  },

  // Inter 12 Regular
  caption: {
    fontFamily: inter,
    display: 'inline-block',
    fontSize: pxToRem(12),
    lineHeight: pxToRem(14.52),
    fontWeight: 400,
    letterSpacing: '0.004em',
  },

  // Inter 12 Regular Button
  button: {
    fontFamily: inter,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(14.52),
    fontWeight: 400,
    letterSpacing: '0.004em',
    textTransform: 'uppercase',
  },
}

const { body1, body2, h5, h6 } = theme.typography

theme.components = {
  ...theme.components,

  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        '& .MuiButton-startIcon': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiButton-endIcon': {
          marginLeft: theme.spacing(0.5),
        },
      },
    },
    variants: [
      {
        props: { size: 'small' },
        style: {
          height: '26px',
        },
      },
      {
        props: { size: 'medium' },
        style: {
          height: '30px',
        },
      },
      {
        props: { size: 'large' },
        style: {
          height: '40px',
        },
      },
      {
        props: { variant: 'contained' },
        style: {
          '&:hover': {
            background: '#633FD2',
          },
        },
      },
      {
        props: { variant: 'contained', color: 'mineShaft' },
        style: {
          color: '#FFFFFF',
          background: theme.palette.mineShaft.main,

          '&:hover': {
            background: theme.palette.mineShaft.main,
          },
        },
      },

      {
        props: { variant: 'contained', color: 'greyBlue' },
        style: {
          color: theme.palette.mineShaft.main,
          backgroundColor: theme.palette.mineShaft[100],
          '&:hover': {
            backgroundColor: theme.palette.mineShaft[200],
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          color: theme.palette.mineShaft.main,
          background: '#FFFFFF',
          border: `1px solid ${theme.palette.mineShaft[200]}`,
          boxShadow: '0px 1px 2px 0px #0000000D',
          transition: '0.25s',

          '&:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.alabaster,
            transition: '0.25s',
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          background: 'transparent',
          color: theme.palette.mineShaft.main,
          ...body1,

          '&:hover': {
            background: '#2F2F2F0D',
          },
        },
      },
    ],
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      selected: {
        background: theme.palette.mediumPurple[100],
      },
      root: {
        minHeight: '32px',
        ...body1,

        // LARGE SCREEN
        [theme.breakpoints.up('lg')]: {
          minHeight: '40px',
        },
      },
    },
  },

  MuiMenu: {
    styleOverrides: {
      list: {
        overflowY: 'auto',
        padding: 0,
        maxHeight: theme.spacing(24),
        ...scrollBarListStyles,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: '32px',
        padding: '6px 12px',

        background: theme.palette.common.white,

        border: '1px solid',
        borderColor: theme.palette.mineShaft[200],
        borderRadius: '6px',

        minWidth: '240px',
        maxWidth: '560px',

        // LARGE SCREEN
        [theme.breakpoints.up('lg')]: {
          height: '40px',
          padding: '8px 12px',
        },

        // FOCUSED and HOVERED(not disabled or error)
        transition: '0.25s',
        '&.Mui-focused, &:hover:not(.Mui-disabled, &.Mui-error)': {
          borderColor: theme.palette.primary.main,
          boxShadow: '0px 0px 4px 0px #00000040 inset, 0px 1px 4px 0px #9A7EF0',
        },

        // ERROR
        '&.Mui-error': {
          background: theme.palette.red[100],

          borderColor: theme.palette.error.main,

          '& .MuiInputBase-input': {
            color: theme.palette.error.main,

            '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
              {
                WebkitBoxShadow: `0 0 0 30px ${theme.palette.red[100]} inset`,
                WebkitTextFillColor: `${theme.palette.error.main} !important`,
              },
          },
        },

        // DISABLED
        '&.Mui-disabled': {
          background: theme.palette.mineShaft[100],
          borderColor: theme.palette.mineShaft[200],
          color: theme.palette.mineShaft[600],
        },

        // ADORNMENT
        '&.MuiInputBase-adornedEnd': {
          paddingRight: '2px',
        },
        '&.MuiInputBase-adornedStart': {
          paddingLeft: '2px',
        },
        '& .MuiInputAdornment-root': {
          maxHeight: 'none',
          height: '100%',
          marginRight: '6px',
          marginLeft: '6px',
        },
      },
      inputAdornedEnd: {
        display: 'block',
        height: '100%',
      },
      multiline: {
        height: 'auto',

        // LARGE SCREEN
        [theme.breakpoints.up('lg')]: {
          height: 'auto',
        },
      },
      input: {
        ...body2,
        color: theme.palette.text.primary,
        padding: 0,
        height: '100%',

        '&::placeholder': {
          color: theme.palette.mineShaft[600],
        },

        // LARGE SCREEN
        [theme.breakpoints.up('lg')]: {
          ...h5,

          '&::placeholder': {
            ...h6,
          },
        },
      },
    },
  },

  MuiPopover: {
    defaultProps: {
      PaperProps: {
        variant: 'elevation',
        sx: {
          border: theme => `1px solid ${theme.palette.primary.main}`,
          borderRadius: '5px',
        },
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },
}
