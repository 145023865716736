import { alpha, Box, Drawer, useTheme } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  filtersContent: React.ReactNode
  children: ReactNode
  filtersPosition?: 'left' | 'right'
  isOpen: boolean
}

const FILTERS_SIDEBAR_WIDTH = 320

export const FiltersTemplate = ({
  filtersPosition = 'right',
  filtersContent,
  children,
  isOpen,
}: Props) => {
  const theme = useTheme()

  return (
    <Box display="flex" flex="auto" minHeight={0}>
      {isOpen && filtersPosition === 'left' && (
        <Drawer
          open={isOpen}
          anchor="left"
          variant="persistent"
          sx={{
            width: isOpen ? `${FILTERS_SIDEBAR_WIDTH}px` : 0,
            zIndex: 1000,
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            '& .MuiDrawer-paper': {
              position: 'static',
              width: isOpen ? `${FILTERS_SIDEBAR_WIDTH}px` : 0,
              border: 'none',
              borderRadius: '0px',
              boxShadow: `-2px -2px 2px 0px ${alpha('#000000', 0.05)}`,
              borderRight: `1px solid ${theme.palette.divider}`,
              overflow: 'hidden',
            },
          }}
        >
          {filtersContent}
        </Drawer>
      )}

      <Box
        sx={{
          position: 'relative',

          flex: 'auto',
          minWidth: 0,
          marginLeft: 0,
          transition: theme =>
            theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <Box height="100%">{children}</Box>
      </Box>

      {filtersPosition === 'right' && (
        <Drawer
          open={isOpen}
          anchor="right"
          variant="persistent"
          sx={{
            width: isOpen ? `${FILTERS_SIDEBAR_WIDTH}px` : 0,
            zIndex: 1000,
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            '& .MuiDrawer-paper': {
              position: 'static',
              width: isOpen ? `${FILTERS_SIDEBAR_WIDTH}px` : 0,
              border: 'none',
              borderRadius: '0px',
              boxShadow: `-2px -2px 2px 0px ${alpha('#000000', 0.05)}`,
              overflow: 'hidden',
            },
          }}
        >
          {filtersContent}
        </Drawer>
      )}
    </Box>
  )
}
