import { IconReverseLeft } from 'assets/icons'
import { useNavigate } from 'react-router'
import { Button, ButtonProps } from 'ui/inputs/button'

type Props = Readonly<{
  buttonProps?: ButtonProps
}>

export const GoBackIconButton = ({ buttonProps }: Props) => {
  const navigate = useNavigate()

  return (
    <Button variant="outlined" onClick={() => navigate(-1)} {...buttonProps}>
      <IconReverseLeft sx={{ fontSize: '16px' }} stroke="2" />
    </Button>
  )
}
