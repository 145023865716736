import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSend = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 40 40"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <g clipPath="url(#clip0_4128_15439)">
      <g clipPath="url(#clip1_4128_15439)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20C40 31.045 31.045 40 20 40C8.955 40 0 31.045 0 20C0 8.955 8.955 0 20 0C31.045 0 40 8.955 40 20ZM20.7167 14.765C18.7717 15.5733 14.8833 17.2483 9.05333 19.7883C8.10667 20.165 7.61 20.5333 7.565 20.8933C7.48833 21.5033 8.25167 21.7433 9.28833 22.0683C9.43 22.1133 9.57667 22.1583 9.72667 22.2083C10.7483 22.54 12.1217 22.9283 12.835 22.9433C13.4833 22.9567 14.2067 22.69 15.005 22.1433C20.4517 18.465 23.2633 16.6067 23.44 16.5667C23.565 16.5383 23.7383 16.5017 23.855 16.6067C23.9717 16.71 23.96 16.9067 23.9483 16.96C23.8717 17.2817 20.8817 20.0633 19.3317 21.5033C18.8483 21.9517 18.5067 22.27 18.4367 22.3433C18.28 22.505 18.12 22.66 17.9667 22.8083C17.0167 23.7217 16.3067 24.4083 18.0067 25.5283C18.8233 26.0667 19.4767 26.5117 20.1283 26.955C20.84 27.44 21.55 27.9233 22.47 28.5267C22.7033 28.68 22.9267 28.8383 23.145 28.9933C23.9733 29.585 24.7183 30.115 25.6383 30.0317C26.1717 29.9817 26.725 29.48 27.005 27.9817C27.6667 24.4383 28.97 16.765 29.2717 13.6017C29.29 13.339 29.2788 13.0751 29.2383 12.815C29.2141 12.6049 29.1118 12.4116 28.9517 12.2733C28.7133 12.0783 28.3433 12.0367 28.1767 12.04C27.425 12.0533 26.2717 12.455 20.7167 14.765Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4128_15439">
        <rect width="40" height="40" fill="white" />
      </clipPath>
      <clipPath id="clip1_4128_15439">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
