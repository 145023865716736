import { TProfile } from 'api/common'
import { TJobRequestProfile } from 'api/job-requests'
import { TJobStatusCode } from 'api/jobs'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TActivityType = t.keyof({
  FOLLOW_UP: null,
  INTERVIEW: null,
})

export type ActivityType = t.TypeOf<typeof TActivityType>

const TActivityChannel = t.keyof({
  EMAIL: null,
  CALL: null,
  SOCIAL_NETWORK: null,
})

export type ActivityChannel = t.TypeOf<typeof TActivityChannel>

const TActivityStatus = t.keyof({
  OPEN: null,
  DONE: null,
  UPCOMING: null,
  EXPIRED: null,
})

export type ActivityStatus = t.TypeOf<typeof TActivityStatus>

export const TActivity = t.intersection([
  t.strict({
    type: TActivityType,
    contact: t.intersection([
      t.strict({
        candidateProfileId: t.string,
        name: t.string,
        positionName: t.string,
      }),
      t.partial({
        imageUrl: t.string,
      }),
    ]),
    status: TActivityStatus,
    startDate: DateFromISOString,
    activityId: t.string,
    automaticallyCreated: t.boolean,
    createdAt: DateFromISOString,
    createdBy: TProfile,
  }),
  t.partial({
    job: t.strict({
      positionName: t.string,
      jobStatus: TJobStatusCode,
      jobId: t.string,
    }),
    notes: t.string,
    channel: TActivityChannel,
    endDate: DateFromISOString,
    link: t.string,
  }),
])

export type Activity = t.TypeOf<typeof TActivity>

export const TActivitiesList = t.array(TActivity)
export type ActivitiesList = t.TypeOf<typeof TActivitiesList>

export const TContactActivity = t.intersection([
  t.strict({
    type: TActivityType,
    startDate: DateFromISOString,
    status: TActivityStatus,
    activityId: t.string,
    createdAt: DateFromISOString,
    createdBy: TProfile,
  }),
  t.partial({
    channel: TActivityChannel,
    job: t.strict({
      positionName: t.string,
      jobStatus: TJobStatusCode,
      jobId: t.string,
    }),
    contact: t.intersection([
      t.strict({
        candidateProfileId: t.string,
        name: t.string,
        positionName: t.string,
      }),
      t.partial({
        imageUrl: t.string,
      }),
    ]),
    notes: t.string,
  }),
])

export type ContactActivity = t.TypeOf<typeof TContactActivity>

export const TContactActivityList = t.array(TContactActivity)
export type ContactActivityList = t.TypeOf<typeof TContactActivityList>

export const TWeeklyActivity = t.strict({
  type: TActivityType,
  startDate: DateFromISOString,
  activityId: t.string,
  status: TActivityStatus,
  createdBy: TJobRequestProfile,
})

export type WeeklyActivity = t.TypeOf<typeof TWeeklyActivity>
