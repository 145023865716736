import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconReloadArrow = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <g clipPath="url(#clip0_4103_599)">
      <path
        d="M2 8.44444C2 8.44444 3.55943 6.31973 4.82632 5.05197C6.0932 3.78421 7.84391 3 9.77778 3C13.6438 3 16.7778 6.13401 16.7778 10C16.7778 13.866 13.6438 17 9.77778 17C6.58648 17 3.89398 14.8644 3.05137 11.9444M2 8.44444V3.77778M2 8.44444H6.66667"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4103_599">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
