import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack } from '@mui/material'
import { ActivityStatus } from 'api'
import { ToastContainerId } from 'app/enums'
import { ControlsLine, ValidationErrors } from 'components/common'
import { ContactAutocomplete } from 'components/contacts'
import { RouteLeavingGuard } from 'components/global'
import { JobsSearch } from 'components/jobs'
import { isSameDay, isValid, startOfDay } from 'date-fns'
import { useConfirmationDialog, useMyTimeZone } from 'lib/app-helpers'
import { getInputError } from 'lib/form-utils'
import { copyTimeToDate } from 'lib/js-utils'
import { setPreviousQuarter } from 'lib/js-utils/date/format-time/set-nearest-quarter'
import { activityChannels, activityTypes, recordToOptions } from 'lib/records'
import { showToast, useDismissToasts } from 'lib/toast'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'
import { DatePicker } from 'ui/inputs/date-picker'
import { renderSelect } from 'ui/inputs/select'
import { renderTextField } from 'ui/inputs/text-field'
import { TimePicker } from 'ui/inputs/time-picker'

import {
  buildSchema,
  defaultValues,
  FormValues,
  labels,
  requiredValues,
  ValidValues,
} from './_values'

type Props = {
  title: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: ValidValues) => void
  isActionLoading: boolean
  initialValues?: Partial<FormValues>
  isEditForm?: boolean
  hint?: string
  isAutomaticallyCreated?: boolean
}

export const ActivityDialogForm = ({
  isOpen,
  title,
  onClose,
  onSubmit,
  initialValues,
  isActionLoading,
  isEditForm,
  hint,
  isAutomaticallyCreated,
}: Props) => {
  const { t } = useTranslation()
  const myTimeZone = useMyTimeZone()
  const schema = useMemo(() => buildSchema(t, myTimeZone), [t, myTimeZone])
  const { dismissToastsByContainerId } = useDismissToasts()

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: { ...defaultValues, ...initialValues },
    resolver: zodResolver(schema),
  })

  const getActivityStatusOptions = (): Array<{
    value: ActivityStatus
    label: string
  }> => {
    if (!isEditForm || !initialValues || !initialValues.status) return []

    return [
      {
        value: initialValues.status,
        label: t(`common.activity_statuses.${initialValues.status}`).toString(),
      },
      ...(initialValues.status === 'DONE'
        ? [
            {
              value: 'OPEN',
              label: t('common.activity_statuses.OPEN').toString(),
            } as const,
          ]
        : [
            {
              value: 'DONE',
              label: t('common.activity_statuses.DONE').toString(),
            } as const,
          ]),
    ]
  }

  useEffect(() => {
    reset()
  }, [isOpen, reset])

  const date = watch('date')

  const confirmClose = useConfirmationDialog<void>(onClose)

  const closeDialog = () => {
    confirmClose.openDialog()
    dismissToastsByContainerId(ToastContainerId.System)
  }

  const startTimeInput = (
    <Controller
      control={control}
      name="startTime"
      render={({ field, fieldState }) => {
        return (
          <TimePicker
            required
            value={field.value}
            startAfter={
              watch('date') && isSameDay(new Date(), watch('date')!)
                ? setPreviousQuarter(watch('date')!)
                : undefined
            }
            onChange={field.onChange}
            label={t(labels.startTime).toString()}
            date={isValid(date) ? date : null}
            minutesStep={15}
            {...getInputError(fieldState.error)}
          />
        )
      }}
    />
  )

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogContent
        wrapWithForm
        title={title}
        subtitle={
          isAutomaticallyCreated && t('hints.automatically_created_activity')
        }
        hint={hint}
        onDeny={closeDialog}
        isActionLoading={isActionLoading}
        onConfirm={handleSubmit(
          values => onSubmit(values as ValidValues),
          errors => {
            showToast(
              {
                type: 'error',
                title: t('toasts.form_not_completed'),
                body: (
                  <ValidationErrors
                    errors={errors}
                    translations={labels}
                    required={requiredValues(getValues('type'))}
                  />
                ),
              },
              { autoClose: false },
            )
          },
        )}
      >
        <Stack spacing={3}>
          <ControlsLine>
            <Controller
              control={control}
              name="type"
              render={renderSelect({
                required: true,
                disableEmptyOption: true,
                disabled: isEditForm,
                label: t(labels.type).toString(),
                options: recordToOptions(activityTypes, value =>
                  t(`common.activity_types.${value}`),
                ),
              })}
            />

            {watch('type') === 'FOLLOW_UP' && (
              <Controller
                control={control}
                name="channel"
                render={renderSelect({
                  required: true,
                  disableEmptyOption: true,
                  label: t(labels.channel).toString(),
                  options: recordToOptions(activityChannels, value =>
                    t(`common.channels.${value}`),
                  ),
                })}
              />
            )}
          </ControlsLine>

          <ControlsLine>
            <Controller
              control={control}
              name="date"
              render={({ field, fieldState }) => (
                <DatePicker
                  required
                  label={t(labels.date).toString()}
                  name="date"
                  calendarOptions={{
                    disabledOptions: {
                      min: startOfDay(new Date()),
                    },
                  }}
                  value={field.value}
                  onChange={newDate => {
                    field.onChange(newDate)
                    const startTime = getValues('startTime')
                    const endTime = getValues('endTime')
                    if (
                      newDate &&
                      isValid(newDate) &&
                      startTime &&
                      isValid(startTime)
                    ) {
                      setValue(
                        'startTime',
                        copyTimeToDate(newDate, startTime),
                        {
                          shouldValidate: true,
                        },
                      )
                    }
                    if (
                      newDate &&
                      isValid(newDate) &&
                      endTime &&
                      isValid(endTime)
                    ) {
                      setValue('endTime', copyTimeToDate(newDate, endTime), {
                        shouldValidate: true,
                      })
                    }
                  }}
                  {...getInputError(fieldState.error)}
                />
              )}
            />

            {watch('type') !== 'INTERVIEW' ? (
              startTimeInput
            ) : (
              <Box width="100%" />
            )}
          </ControlsLine>

          {watch('type') === 'INTERVIEW' && (
            <ControlsLine>
              {startTimeInput}

              <Controller
                control={control}
                name="endTime"
                render={({ field, fieldState }) => {
                  return (
                    <TimePicker
                      required
                      value={field.value!}
                      startAfter={watch('startTime') ?? undefined}
                      onChange={field.onChange}
                      label={t(labels.endTime).toString()}
                      date={isValid(date) ? date : null}
                      minutesStep={15}
                      {...getInputError(fieldState.error)}
                    />
                  )
                }}
              />
            </ControlsLine>
          )}

          <ControlsLine>
            <Controller
              control={control}
              name="jobId"
              render={({ field, fieldState }) => (
                <JobsSearch
                  label={t(labels.jobId).toString()}
                  value={field.value}
                  onChange={field.onChange}
                  {...getInputError(fieldState.error)}
                />
              )}
            />

            <Controller
              control={control}
              name="contact"
              render={({ field, fieldState }) => (
                <ContactAutocomplete
                  required
                  label={t(labels.contact).toString()}
                  value={field.value}
                  onChange={field.onChange}
                  disableAlphabeticalOrder
                  disabled={!isEditForm && initialValues?.contact !== ''}
                  {...getInputError(fieldState.error)}
                />
              )}
            />
          </ControlsLine>

          <ControlsLine>
            {watch('type') === 'INTERVIEW' && (
              <Controller
                control={control}
                name="link"
                render={renderTextField({
                  // eslint-disable-next-line security/detect-non-literal-fs-filename
                  label: t(labels.link).toString(),
                })}
              />
            )}
            {isEditForm && (
              <Controller
                control={control}
                name="status"
                render={renderSelect({
                  required: true,
                  disableEmptyOption: true,
                  label: t(labels.status).toString(),
                  options: getActivityStatusOptions(),
                  disableAlphabeticalOrder: true,
                })}
              />
            )}
          </ControlsLine>

          <Controller
            control={control}
            name="notes"
            render={renderTextField({
              label: t(labels.notes).toString(),
              multiline: true,
              minRows: 3,
              maxRows: 7,
            })}
          />
        </Stack>

        {confirmClose.renderConfirmDialog({
          size: 'small',
          variant: 'danger',
          title: t('common.changes_wont_be_saved'),
          children: t('common.cant_save_data'),
        })}

        <RouteLeavingGuard when={isDirty && !isSubmitSuccessful} />
      </DialogContent>
    </Dialog>
  )
}
