import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  Box,
  CSSObject,
  Skeleton,
} from '@mui/material'
import defaultCompanyImage from 'assets/images/defaultCompany.png'
import defaultUserImage from 'assets/images/defaultUser.svg'

export type AvatarProps = MuiAvatarProps & {
  /** width and height in px */
  size?: number
  /** show circular skeleton if true */
  isLoading?: boolean
  /** fallback to default company picture */
  company?: boolean
}

export const Avatar = ({
  size,
  isLoading = false,
  company = false,
  variant,
  ...props
}: AvatarProps) => {
  if (isLoading) {
    return <Skeleton variant="circular" width={size} height={size} {...props} />
  }

  const sizeStyles: CSSObject = {
    width: size,
    height: size,
  }

  return (
    <MuiAvatar
      variant={variant ?? 'circular'}
      {...props}
      sx={{
        background: 'none',
        border: `2px solid white`,
        ...sizeStyles,
        ...props.sx,
      }}
    >
      {company ? (
        <Box
          component="img"
          src={defaultCompanyImage}
          alt="Default company logo"
          sx={sizeStyles}
        />
      ) : (
        <Box
          component="img"
          src={defaultUserImage}
          alt="Default user avatar"
          sx={sizeStyles}
        />
      )}
    </MuiAvatar>
  )
}
