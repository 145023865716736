import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSettingsJobs = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M2.08392 7.5H17.9173M8.33392 2.5L6.66726 7.5L10.0006 17.0833L13.3339 7.5L11.6673 2.5M10.5127 16.8854L17.9783 7.92679C18.1048 7.77496 18.1681 7.69905 18.1922 7.6143C18.2135 7.53959 18.2135 7.46041 18.1922 7.38569C18.1681 7.30095 18.1048 7.22504 17.9783 7.07321L14.3672 2.73988C14.2937 2.6517 14.2569 2.60762 14.2119 2.57592C14.172 2.54784 14.1275 2.52698 14.0804 2.51431C14.0272 2.5 13.9698 2.5 13.855 2.5H6.14617C6.0314 2.5 5.97401 2.5 5.92083 2.51431C5.87371 2.52698 5.82918 2.54784 5.78928 2.57592C5.74424 2.60762 5.7075 2.6517 5.63403 2.73988L2.02292 7.07321C1.89639 7.22504 1.83313 7.30095 1.80895 7.38569C1.78764 7.46041 1.78764 7.53959 1.80895 7.6143C1.83313 7.69904 1.89639 7.77496 2.02291 7.92679L9.48844 16.8854C9.66432 17.0965 9.75226 17.202 9.85755 17.2405C9.94992 17.2743 10.0513 17.2743 10.1436 17.2405C10.2489 17.202 10.3369 17.0965 10.5127 16.8854Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
