export const ArrowLeftIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1"
      focusable="false"
    >
      <path
        d="M10.0902 4.21864L6.17683 8.13203L10.0902 12.0454"
        stroke="#2F2F2F"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ArrowRightIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      {...props}
      className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1"
      focusable="false"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.95995 12.0455L9.87333 8.13213L5.95995 4.21875"
        stroke="#2F2F2F"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
