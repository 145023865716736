import '../date-range-picker/date-range-style.css'

import { Box, Popover, Stack, Typography } from '@mui/material'
import { months } from 'lib/js-utils'
import { useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

import { CloseIconButton } from '../close-icon-button'
import { ArrowLeftIcon, ArrowRightIcon } from '../date-range-picker/arrows'
import { Select } from '../select'

const NUMBER_OF_MONTHS = 2
const YEARS_COUNT = 200

const createYearsRange = () => {
  let firstYear = new Date().getFullYear() - YEARS_COUNT / 2
  return Array.from({ length: YEARS_COUNT }).map(() => firstYear++)
}

const yearsRange = createYearsRange()

export type CalendarPickerOptions = Partial<{
  disabledOptions: Partial<{
    min: Date
    max: Date
    dates: Array<Date>
  }>
  isShowMonthAndYearSelect: boolean
}>

export type Props = {
  value: Date | null
  onChange: (date: Date) => void
  isOpen: boolean
  onClose: () => void
  anchorElement: HTMLElement | null
  options?: CalendarPickerOptions
  texts?: Partial<{
    title: string
    subtitle: string
  }>
}

export const CalendarPicker = ({
  value,
  onChange,
  anchorElement,
  isOpen,
  onClose,
  options,
  texts,
}: Props) => {
  const { t } = useTranslation()

  const defaultDate = value ?? undefined
  const [selectedDate, setSelectedDate] = useState(defaultDate)
  const [currentMonth, setCurrentMonth] = useState(defaultDate || new Date())

  const handleMonthChange = (month: number) => {
    const newDate = new Date(currentMonth)
    newDate.setMonth(month)
    setCurrentMonth(newDate)
  }

  const handleYearChange = (year: number) => {
    const newDate = new Date(currentMonth)
    newDate.setFullYear(year)
    setCurrentMonth(newDate)
  }

  const handleClose = () => {
    onClose()
    setSelectedDate(defaultDate)
  }

  const getDisabledDays = () => {
    if (!options?.disabledOptions) return

    const { min: minDate, max: maxDate, dates } = options.disabledOptions

    const disabledDays = []

    if (minDate) {
      disabledDays.push({
        before: minDate,
      })
    }

    if (maxDate) {
      disabledDays.push({
        after: maxDate,
      })
    }

    if (dates) {
      disabledDays.push(dates)
    }

    return disabledDays
  }

  // Фільтруємо доступні місяці на основі мінімальної та максимальної дати
  const filteredMonths = months.filter((_, index) => {
    if (options?.disabledOptions?.min && options.disabledOptions.max) {
      const minMonth =
        options.disabledOptions.min.getFullYear() === currentMonth.getFullYear()
          ? options.disabledOptions.min.getMonth()
          : 0
      const maxMonth =
        options.disabledOptions.max.getFullYear() === currentMonth.getFullYear()
          ? options.disabledOptions.max.getMonth()
          : 11
      return index >= minMonth && index <= maxMonth
    }
    return true
  })

  // Фільтруємо доступні роки на основі мінімальної та максимальної дати
  const filteredYearsRange = yearsRange.filter(year => {
    const minYear =
      options?.disabledOptions?.min?.getFullYear() ?? Number.NEGATIVE_INFINITY
    const maxYear =
      options?.disabledOptions?.max?.getFullYear() ?? Number.POSITIVE_INFINITY
    return year >= minYear && year <= maxYear
  })

  return (
    <Popover
      anchorEl={anchorElement}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        variant: 'elevation',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Stack spacing={2} sx={{ padding: '24px' }}>
        <Box>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Box display="flex">
              <Typography variant="h2">
                {texts?.title || t('common.select_date')}
              </Typography>
            </Box>

            <Box>
              <CloseIconButton onClose={handleClose} />
            </Box>
          </Stack>

          {texts?.subtitle && (
            <Typography color="text.secondary" mt={2}>
              {texts.subtitle}
            </Typography>
          )}
        </Box>

        <Stack direction="column" spacing={2}>
          {options?.isShowMonthAndYearSelect && (
            <Stack direction="row" spacing={2}>
              <Box>
                <Select
                  value={currentMonth.getMonth().toString()}
                  onChange={selectedMonth =>
                    handleMonthChange(Number(selectedMonth))
                  }
                  disableEmptyOption
                  disableAlphabeticalOrder
                  options={filteredMonths.map((month, index) => ({
                    value: index.toString(),
                    label: t(month).toString(),
                  }))}
                />
              </Box>

              <Box>
                <Select
                  value={currentMonth.getFullYear().toString()}
                  onChange={selectedMonth =>
                    handleYearChange(Number(selectedMonth))
                  }
                  disableEmptyOption
                  options={filteredYearsRange.map(year => ({
                    value: year.toString(),
                    label: year.toString(),
                  }))}
                />
              </Box>
            </Stack>
          )}

          <DayPicker
            mode="single"
            numberOfMonths={NUMBER_OF_MONTHS}
            onMonthChange={setCurrentMonth}
            month={currentMonth}
            selected={selectedDate}
            disabled={getDisabledDays()}
            onSelect={setSelectedDate}
            components={{
              IconLeft: ArrowLeftIcon,
              IconRight: ArrowRightIcon,
            }}
          />
        </Stack>

        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            minWidth="160px"
            color="greyBlue"
            onClick={handleClose}
          >
            {t('common.cancel')}
          </Button>

          <Button
            disabled={!selectedDate}
            minWidth="160px"
            onClick={() => {
              if (selectedDate) {
                onChange(selectedDate)
                onClose()
              }
            }}
          >
            {t('common.apply')}
          </Button>
        </Stack>
      </Stack>
    </Popover>
  )
}
