import { useTheme } from '@mui/material'
import { RichTextEditor } from 'components/jobs'
import { ColorBox } from 'ui/data/color-box'

type Props = Readonly<{
  notes: string
}>

export const ContactNotesSection = ({ notes }: Props) => {
  const theme = useTheme()

  return (
    <ColorBox
      color={theme.palette.selectiveYellow[100]}
      borderColor={theme.palette.selectiveYellow.main}
    >
      <RichTextEditor value={notes} readOnly />
    </ColorBox>
  )
}
