import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { atLeastOneSymbolRegex } from 'lib/form-utils'
import { findDuplicates, splitCommaSeparatedString } from 'lib/js-utils'
import { showToast } from 'lib/toast'
import { useCallback, useMemo } from 'react'
import {
  Controller,
  ControllerRenderProps,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { Badge, BadgeCollection } from 'ui/data'
import { Button } from 'ui/inputs/button'
import { TextField } from 'ui/inputs/text-field'
import { z } from 'zod'

type ValuesValidations = {
  minValueLength: number
  maxValueLength: number
  minAmount: {
    value: number
    errorMessage: string
  }
  maxAmount: {
    value: number
    errorMessage: string
  }
  isPosition?: boolean
}

export type SettingsCollection = Array<{
  id?: string
  name: string
  isDisabled?: boolean
}>

type Props = Readonly<
  {
    formId: string
    onSubmit: (values: SettingsCollection) => void
    initialValues: SettingsCollection
  } & ValuesValidations
>

const buildSchema = (
  t: TFunction,
  {
    minValueLength,
    maxValueLength,
    minAmount,
    maxAmount,
    isPosition = false,
  }: ValuesValidations,
) =>
  z.object({
    newValuesInput: z.string().trim(),
    values: z
      .array(
        z.object({
          id: z.string().optional(),
          name: z
            .string()
            .min(minValueLength)
            .max(maxValueLength)
            .refine(
              value => (isPosition ? value.match(atLeastOneSymbolRegex) : true),
              {
                message: t('validations.position_name_no_letter'),
              },
            ),
          isDisabled: z.boolean().optional(),
        }),
      )
      .refine(values => values.length >= minAmount.value, {
        message: minAmount.errorMessage,
      })
      .refine(values => values.length <= maxAmount.value, {
        message: maxAmount.errorMessage,
      })
      .refine(
        values => {
          const duplicates = findDuplicates(
            values.map(value => value.name).filter(Boolean),
          )

          return duplicates.length === 0
        },
        values => {
          const duplicates = findDuplicates(
            values.map(value => value.name).filter(Boolean),
          )

          return {
            message: t('common.duplicates_found', {
              duplicates: duplicates.join(', '),
            }),
          }
        },
      ),
  })

export const EditSettingsCollectionForm = ({
  formId,
  onSubmit,
  initialValues,
  minAmount,
  maxAmount,
  minValueLength,
  maxValueLength,
  isPosition,
}: Props) => {
  const { t } = useTranslation()

  const schema = useMemo(
    () =>
      buildSchema(t, {
        minAmount,
        maxAmount,
        minValueLength,
        maxValueLength,
        isPosition,
      }),
    [t, minAmount, maxAmount, minValueLength, maxValueLength, isPosition],
  )

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    defaultValues: {
      newValuesInput: '',
      values: initialValues,
    },
    resolver: zodResolver(schema),
  })

  const values = useFieldArray({
    control,
    name: 'values',
    keyName: 'itemId',
  })

  const handleAdd = useCallback(
    (
      field: ControllerRenderProps<z.infer<typeof schema>, 'newValuesInput'>,
    ) => {
      return () => {
        if (!field.value) return

        values.append(
          splitCommaSeparatedString(field.value).map(value => ({
            name: value,
          })),
        )

        field.onChange('')
      }
    },
    [values],
  )

  return (
    <form
      id={formId}
      onSubmit={event => {
        event.preventDefault()

        const newValuesInput = getValues('newValuesInput')

        if (newValuesInput.trim().length > 0) {
          showToast({
            type: 'error',
            title: t('toasts.edit_collection_click_add_to_proceed'),
          })
          return
        }

        handleSubmit(({ values }) => {
          onSubmit(values)
        })(event)
      }}
    >
      <Controller
        name="newValuesInput"
        control={control}
        render={({ field }) => (
          <Stack direction="row" spacing={2}>
            <TextField
              {...field}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  handleAdd(field)()
                }
              }}
              helperText={t('common.helper_text')}
            />

            <Button onClick={handleAdd(field)}>{t('common.add')}</Button>
          </Stack>
        )}
      />

      {values.fields.length > 0 && (
        <Box mt={2}>
          <BadgeCollection>
            {values.fields.map((item, index) => (
              <Controller
                key={item.itemId}
                control={control}
                name={`values.${index}.name` as const}
                render={({ field, fieldState }) => (
                  <Tooltip
                    arrow
                    placement="top"
                    disableInteractive
                    title={fieldState.error?.message ?? ''}
                  >
                    <Badge
                      error={Boolean(fieldState.error)}
                      isDisabled={item.isDisabled}
                      onChange={value => {
                        field.onChange(value)
                      }}
                      onRemove={() => {
                        values.remove(index)
                      }}
                    >
                      {field.value}
                    </Badge>
                  </Tooltip>
                )}
              />
            ))}
          </BadgeCollection>
        </Box>
      )}

      {errors.values && errors.values.message && (
        <Typography variant="caption" color="error.main" mt={2}>
          {errors.values.message}
        </Typography>
      )}
    </form>
  )
}
