import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconOffice = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M5.00065 4.66667H6.83398M5.00065 7.33333H6.83398M5.00065 10H6.83398M9.16732 4.66667H11.0007M9.16732 7.33333H11.0007M9.16732 10H11.0007M13.334 14V4.13333C13.334 3.3866 13.334 3.01323 13.1887 2.72801C13.0608 2.47713 12.8569 2.27316 12.606 2.14532C12.3208 2 11.9474 2 11.2007 2H4.80065C4.05391 2 3.68055 2 3.39533 2.14532C3.14445 2.27316 2.94047 2.47713 2.81264 2.72801C2.66732 3.01323 2.66732 3.3866 2.66732 4.13333V14M14.6673 14H1.33398"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
