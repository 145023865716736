import throttle from 'lodash/throttle'
import { useCallback, useLayoutEffect, useState } from 'react'

export const useScrollPercentage = (
  ref: React.RefObject<HTMLElement>,
  throttleDelay = 100,
) => {
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [scrollbarHeightPercentage, setScrollbarHeightPercentage] =
    useState(100)

  const calculateScrollMetrics = useCallback(() => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current
      const totalScroll = scrollHeight - clientHeight
      const scrollPercent =
        totalScroll > 0 ? (scrollTop / totalScroll) * 100 : 0
      setScrollPercentage(scrollPercent)

      const scrollbarHeightPercent =
        scrollHeight > 0 ? (clientHeight / scrollHeight) * 100 : 100
      setScrollbarHeightPercentage(scrollbarHeightPercent)
    }
  }, [ref])

  useLayoutEffect(() => {
    const element = ref.current

    if (element) {
      const throttledCalculateScroll = throttle(
        calculateScrollMetrics,
        throttleDelay,
      )

      element.addEventListener('scroll', throttledCalculateScroll)
      calculateScrollMetrics()

      return () => {
        element.removeEventListener('scroll', throttledCalculateScroll)
        throttledCalculateScroll.cancel()
      }
    }
    return () => {}
  }, [ref, calculateScrollMetrics, throttleDelay])

  return { scrollPercentage, scrollbarHeightPercentage }
}
