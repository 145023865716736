import { Box } from '@mui/material'
import { ReactNode } from 'react'

export const ColorBox = ({
  children,
  color,
  borderColor,
}: {
  children: ReactNode
  color?: string
  borderColor?: string
}) => {
  return (
    <Box
      sx={{
        bgcolor: theme => color ?? theme.palette.primary.main,
        borderColor: theme => borderColor ?? theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '8px',
        py: 2,
        px: 2,
      }}
    >
      {children}
    </Box>
  )
}
