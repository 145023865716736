import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack } from '@mui/material'
import { CountryAutocomplete } from 'components/location/country-autocomplete'
import { getInputError } from 'lib/form-utils'
import { Controller, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { Dialog, DialogContent, DialogContentProps } from 'ui/feedback'
import { DatePicker } from 'ui/inputs/date-picker'
import { z } from 'zod'

const FIELDS_SPACING = '16px'
const HALF_WIDTH_FIELD = `calc(50% - (${FIELDS_SPACING}/2))`

const buildSchema = (t: TFunction) => {
  return z
    .object({
      country: z.string().min(1),
      startDate: z.date(),
      expiredDate: z.date(),
    })
    .refine(data => data.startDate <= data.expiredDate, {
      message: t('validations.expired_date_work_permit'),
      path: ['expiredDate'],
    })
}

type ValidValues = z.infer<ReturnType<typeof buildSchema>>

type FormValues = {
  country: string
  startDate: Date | null
  expiredDate: Date | null
}

type Props = Readonly<{
  isOpen: boolean
  onClose: () => void
  dialogContentProps: Omit<DialogContentProps, 'children'>
  initialValues: FormValues
  onSubmit: (value: ValidValues) => void
  isResetOnSubmit?: boolean
}>

export const WorkPermitFormDialog = ({
  isOpen,
  onClose,
  dialogContentProps,
  initialValues,
  onSubmit,
  isResetOnSubmit,
}: Props) => {
  const { t } = useTranslation()

  const schema = buildSchema(t)
  const { control, handleSubmit, reset, trigger, watch } = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: zodResolver(schema),
  })

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        {...dialogContentProps}
        onDeny={onClose}
        onConfirm={handleSubmit(values => {
          onSubmit(values as ValidValues)
          isResetOnSubmit && reset()
          onClose()
        })}
        wrapWithForm
      >
        <Stack spacing={FIELDS_SPACING}>
          <Box width={HALF_WIDTH_FIELD}>
            <Controller
              name="country"
              control={control}
              render={({ field, fieldState }) => (
                <CountryAutocomplete
                  label={t('common.country')}
                  placeholder={t('common.select_option')}
                  value={field.value}
                  onChange={field.onChange}
                  required
                  {...getInputError(fieldState.error)}
                />
              )}
            />
          </Box>

          <Stack direction="row" spacing={FIELDS_SPACING}>
            <Controller
              name={`startDate`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <DatePicker
                    label={t('common.start_date')}
                    value={field.value}
                    name={`startDate`}
                    onChange={value => {
                      field.onChange(value)
                      trigger(['expiredDate'])
                    }}
                    required
                    {...getInputError(fieldState.error)}
                  />
                )
              }}
            />

            <Controller
              name={`expiredDate`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <DatePicker
                    label={t('common.expired_date')}
                    value={field.value}
                    name={`expiredDate`}
                    inputBaseProps={{
                      disabled: !watch('startDate'),
                    }}
                    calendarOptions={{
                      disabledOptions: {
                        min:
                          watch('startDate') !== null
                            ? watch('startDate')!
                            : new Date(),
                      },
                    }}
                    onChange={value => {
                      field.onChange(value)
                      trigger(['expiredDate'])
                    }}
                    required
                    {...getInputError(fieldState.error)}
                  />
                )
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
