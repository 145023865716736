export enum LocalStorageKey {
  SkillsRowsPerPage = 'skills-rows-per-page',
  UsersRowsPerPage = 'users-rows-per-page',
  JobsRowsPerPage = 'jobs-rows-per-page',
  JobHistoryRowsPerPage = 'job-history-rows-per-page',
  ContactsRowsPerPage = 'contacts-rows-per-page',
  ContactsRowsPerPageSmall = 'contacts-rows-per-page-small',
  ContactsEmailRowsPerPage = 'contacts-email-rows-per-page',
  ContactsHistoryFiltersRowsPerPage = 'contacts-history-filters-rows-per-page',
  ContactsSavedFiltersRowsPerPage = 'contacts-saved-filters-rows-per-page',
  ContactHistoryRowsPerPage = 'contact-history-rows-per-page',
  TalentsPoolsRowsPerPage = 'talents-pools-rows-per-page',
  JobRequestsRowsPerPage = 'job-requests-rows-per-page',
  NotificationsRowsPerPage = 'notifications-rows-per-page',
  ActivitiesView = 'activities-view',
  ActivitiesDateRange = 'activities-date-range',
  TeamDashboardActivitiesStatusFilter = 'team-dashboard-activities-status-filter',
  TeamDashboardActivitiesRecruiterFilter = 'team-dashboard-activities-recruiter-filter',
  TeamDashboardJobPerformanceStatusFilter = 'team-dashboard-job-performance-status-filter',
  TeamDashboardJobPerformanceRecruiterFilter = 'team-dashboard-job-performance-recruiter-filter',
  MailboxPerPage = 'mailbox-per-page',
  ImportHistoryPerPage = 'import-history-per-page',
  ImportContractsDetails = 'import-contracts-details',
  UpdateCustomFields = 'update-custom-fields',
  EmailTemplates = 'email-templates',
  InvitationId = 'invitation-id',
  InvitationHash = 'invitation-hash',
  ChargesRowsPerPage = 'charges-rows-per-page',
  PromotionSlug = 'promotion-slug',
  IsPromotionExpired = 'is-promotion-expired',
  IsShowRejectedContacts = 'is-show-rejected-contacts',
  PasswordTimerSeconds = 'password-timer-seconds',
  PasswordTimerStartDate = 'password-timer-start-date',
}
