import './date-range-style.css'

import { Box, Popover, Stack, Typography } from '@mui/material'
import { format, isSameDay } from 'date-fns'
import { useBoolean } from 'lib/react-utils'
import { useRef, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from 'ui/navigation'

import { Button } from '../button'
import { CloseIconButton } from '../close-icon-button'
import { ArrowLeftIcon, ArrowRightIcon } from './arrows'
import { commonDateRanges } from './common-date-ranges'
import { DateRangeInput } from './date-range-input'

const MONTH_WIDTH = '280px'
const NUMBER_OF_MONTHS = 2
const MONTH_SPACING = NUMBER_OF_MONTHS * 14 + 'px'

export type DateRangeValue = { from: Date; to: Date }

type Props = Readonly<{
  value?: DateRangeValue
  onChange: (value: DateRangeValue) => void
  disabledDaysOptions?: Partial<{
    min?: Date
    max?: Date
  }>
  texts?: Partial<{
    label: string
    title: string
    subtitle: string
    placeholder: {
      from: string
      to: string
    }
  }>
}>

export const DateRangePicker = ({
  value,
  onChange,
  disabledDaysOptions,
  texts,
}: Props) => {
  const { t } = useTranslation()

  const defaultRange = value
    ? {
        from: value.from,
        to: value.to,
      }
    : undefined
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    defaultRange,
  )
  const formattedValue = {
    from: value ? format(value.from!, 'MM/dd/yyyy') : undefined,
    to: value ? format(value.to!, 'MM/dd/yyyy') : undefined,
  }

  const getDisabledDays = () => {
    if (!disabledDaysOptions) return

    const { min: minDate, max: maxDate } = disabledDaysOptions

    const disabledDays = []

    if (minDate) {
      disabledDays.push({
        before: minDate,
      })
    }

    if (maxDate) {
      disabledDays.push({
        after: maxDate,
      })
    }

    return disabledDays
  }

  const calendarPopoverRef = useRef<HTMLElement | null>(null)
  const showCalendarDialog = useBoolean()

  const handleInputClick = () => {
    showCalendarDialog.setTrue()
  }

  const handleClose = () => {
    showCalendarDialog.setFalse()
    setSelectedRange(defaultRange)
  }

  return (
    <Stack spacing={1} ref={calendarPopoverRef}>
      {texts?.label && (
        <Typography variant="body1" color="mineShaft[900]">
          {texts?.label}
        </Typography>
      )}

      <Box>
        <Stack direction="row" gap={2} flexWrap="wrap">
          <DateRangeInput
            onClick={handleInputClick}
            value={formattedValue.from}
            placeholder={texts?.placeholder?.from ?? t('common.from')}
          />

          <DateRangeInput
            onClick={handleInputClick}
            value={formattedValue.to}
            placeholder={texts?.placeholder?.to ?? t('common.to')}
          />
        </Stack>

        <Popover
          anchorEl={calendarPopoverRef.current}
          open={showCalendarDialog.isTrue}
          onClose={showCalendarDialog.setFalse}
          PaperProps={{
            variant: 'elevation',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Stack spacing={2} sx={{ padding: '24px' }}>
            <Box>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Box display="flex">
                  <Typography variant="h2">
                    {texts?.title || texts?.label || t('common.set_period')}
                  </Typography>
                </Box>

                <Box>
                  <CloseIconButton onClose={handleClose} />
                </Box>
              </Stack>

              {texts?.subtitle && (
                <Typography color="text.secondary" mt={2}>
                  {texts.subtitle}
                </Typography>
              )}
            </Box>

            <Stack direction="column" spacing={2}>
              <Tabs
                scrollButtons="auto"
                variant="scrollable"
                sx={{
                  spacing: MONTH_SPACING,
                  maxWidth: `calc(${NUMBER_OF_MONTHS} * ${MONTH_WIDTH} + ${MONTH_SPACING})`,
                }}
                TabScrollButtonProps={{
                  sx: {
                    width: 'fit-content',
                    color: '#2F2F2F',
                    paddingX: '12px',
                    fontWeight: 400,
                  },
                }}
                visibleScrollbar={false}
              >
                <Stack direction="row" spacing={0.5} py={0.5}>
                  {commonDateRanges.map(currentRange => {
                    const from = currentRange.from
                    const to = currentRange.to
                    const isActive =
                      selectedRange?.from && selectedRange.to
                        ? isSameDay(from, selectedRange.from) &&
                          isSameDay(to, selectedRange.to)
                        : false

                    return (
                      <Tab
                        key={currentRange.name}
                        component={() => {
                          return (
                            <Button
                              variant={isActive ? 'contained' : 'text'}
                              color={isActive ? 'primary' : 'greyBlue'}
                              onClick={() => {
                                setSelectedRange({ from, to })
                              }}
                              sx={{ padding: '16px' }}
                            >
                              <Typography variant="inherit">
                                {t(currentRange.name) as string}
                              </Typography>
                            </Button>
                          )
                        }}
                      />
                    )
                  })}
                </Stack>
              </Tabs>

              <DayPicker
                mode="range"
                numberOfMonths={NUMBER_OF_MONTHS}
                defaultMonth={new Date()}
                disabled={getDisabledDays()}
                weekStartsOn={0}
                selected={selectedRange}
                onSelect={selected => setSelectedRange(selected)}
                components={{
                  IconLeft: ArrowLeftIcon,
                  IconRight: ArrowRightIcon,
                }}
              />
            </Stack>

            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                minWidth="160px"
                color="greyBlue"
                onClick={handleClose}
              >
                {t('common.cancel')}
              </Button>

              <Button
                disabled={!selectedRange?.from || !selectedRange?.to}
                minWidth="160px"
                onClick={() => {
                  if (selectedRange?.from && selectedRange.to) {
                    onChange({
                      from: selectedRange.from,
                      to: selectedRange.to,
                    })
                    showCalendarDialog.setFalse()
                    calendarPopoverRef.current?.focus()
                  }
                }}
              >
                {t('common.apply')}
              </Button>
            </Stack>
          </Stack>
        </Popover>
      </Box>
    </Stack>
  )
}
