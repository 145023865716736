import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSettingsContacts = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M11.6673 9.1665H6.66732M8.33398 12.4998H6.66732M13.334 5.83317H6.66732M16.6673 5.6665V14.3332C16.6673 15.7333 16.6673 16.4334 16.3948 16.9681C16.1552 17.4386 15.7727 17.821 15.3023 18.0607C14.7675 18.3332 14.0674 18.3332 12.6673 18.3332H7.33398C5.93385 18.3332 5.23379 18.3332 4.69901 18.0607C4.2286 17.821 3.84615 17.4386 3.60647 16.9681C3.33398 16.4334 3.33398 15.7333 3.33398 14.3332V5.6665C3.33398 4.26637 3.33398 3.56631 3.60647 3.03153C3.84615 2.56112 4.2286 2.17867 4.69901 1.93899C5.23379 1.6665 5.93385 1.6665 7.33398 1.6665H12.6673C14.0674 1.6665 14.7675 1.6665 15.3023 1.93899C15.7727 2.17867 16.1552 2.56112 16.3948 3.03153C16.6673 3.56631 16.6673 4.26637 16.6673 5.6665Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
