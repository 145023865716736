import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSettingsGeneral = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M6.24935 5.83333H8.54102M6.24935 9.16667H8.54102M6.24935 12.5H8.54102M11.4577 5.83333H13.7493M11.4577 9.16667H13.7493M11.4577 12.5H13.7493M16.666 17.5V5.16667C16.666 4.23325 16.666 3.76654 16.4844 3.41002C16.3246 3.09641 16.0696 2.84144 15.756 2.68166C15.3995 2.5 14.9328 2.5 13.9993 2.5H5.99935C5.06593 2.5 4.59922 2.5 4.2427 2.68166C3.92909 2.84144 3.67413 3.09641 3.51434 3.41002C3.33268 3.76654 3.33268 4.23325 3.33268 5.16667V17.5M18.3327 17.5H1.66602"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
