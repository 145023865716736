import { Stack, Typography } from '@mui/material'
import { isPast } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker } from 'ui/inputs/date-picker'

/**
 * Hook for managing and updating the target date.
 *
 * @returns {object} - Object with the components and state related to the target date.
 * @property {function} TargetDateUpdater - Component to render the target date updater.
 * @property {Date | null} targetDate - The current target date.
 * @property {boolean} isTargetDateError - Boolean indicating if the target date is in the past.
 */
export const useTargetDateUpdater = () => {
  const { t } = useTranslation()

  const [targetDate, setTargetDate] = useState<Date | null>(new Date())
  const isTargetDateError = targetDate === null ? false : isPast(targetDate)

  /**
   * Component to render the target date updater.
   *
   * @returns {JSX.Element} - The JSX element for the target date updater.
   */
  const TargetDateUpdater = () => (
    <Stack spacing={2}>
      <Typography>{t('jobs.open_job_with_past_target_date')}</Typography>

      <DatePicker
        required
        name="targetDatePicker"
        label={t('common.target_date')}
        value={targetDate}
        onChange={setTargetDate}
        error={isTargetDateError}
        helperText={
          isTargetDateError ? t('validations.future_date') : undefined
        }
        inputBaseProps={{
          dataCy: 'targetDatePicker',
        }}
      />
    </Stack>
  )

  return {
    TargetDateUpdater,
    targetDate,
    isTargetDateError,
  }
}
