import { Box, Stack, Tooltip } from '@mui/material'
import { IconArrowLeftBar, IconClear } from 'assets/icons'
import { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { IconButton } from 'ui/inputs/icon-button'
import { InlineTab, InlineTabs } from 'ui/navigation'

import { FiltersIconButton } from './filters-icon-button'

type Props = {
  onApply: () => void
  onReset: () => void
  resetDisabled?: boolean
  onClose?: () => void
  actions?: ReactNode
  tabs: Array<{
    id: string
    icon: ReactNode
    component: ReactNode
    withFooter?: boolean
  }>
}

export const FiltersSidebar = (props: Props) => {
  const { t } = useTranslation()

  const [activeTabId, setActiveTabId] = useState(props.tabs[0]?.id)

  const activeTab = useMemo(
    () => props.tabs.find(tab => tab.id === activeTabId) ?? null,
    [activeTabId, props.tabs],
  )

  if (activeTab === null) {
    return null
  }

  const { component: activeTabContent, withFooter = true } = activeTab

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="flex-end"
        pt="6px"
        px="16px"
        borderBottom="1px solid #E5E5E8"
      >
        <InlineTabs
          value={activeTabId}
          onChange={(_, tab) => {
            setActiveTabId(tab)
          }}
        >
          {props.tabs.map(tab => {
            return (
              <InlineTab
                label={
                  <Box display="flex" justifyContent="center" width="44px">
                    {tab.icon}
                  </Box>
                }
                key={tab.id}
                value={tab.id}
              />
            )
          })}
        </InlineTabs>

        <Box ml="auto" alignSelf="center">
          <IconButton onClick={props.onClose}>
            <Tooltip title="Hide filters">
              <Box>
                <IconArrowLeftBar sx={{ transform: 'scaleX(-1)' }} />
              </Box>
            </Tooltip>
          </IconButton>
        </Box>
      </Box>

      <Stack overflow="auto" flex="auto" spacing={2} padding={2}>
        {activeTabContent}
      </Stack>

      {withFooter && props.onApply && (
        <Box mt="auto">
          <Stack direction="row" p={1.5} spacing={1}>
            <FiltersIconButton
              title={t('common.reset_filters')}
              onClick={props.onReset}
              iconComponent={IconClear}
              disabled={props.resetDisabled}
            />

            {props.actions}

            <Button fullWidth onClick={props.onApply} size="large">
              {t('common.apply')}
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  )
}
