import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSettingsKpiSettings = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M13.3326 6.6665V4.1665L15.8326 1.6665L16.666 3.33317L18.3326 4.1665L15.8326 6.6665H13.3326ZM13.3326 6.6665L9.99932 9.99979M18.3327 9.99984C18.3327 14.6022 14.6017 18.3332 9.99935 18.3332C5.39698 18.3332 1.66602 14.6022 1.66602 9.99984C1.66602 5.39746 5.39698 1.6665 9.99935 1.6665M14.166 9.99984C14.166 12.301 12.3005 14.1665 9.99935 14.1665C7.69816 14.1665 5.83268 12.301 5.83268 9.99984C5.83268 7.69865 7.69816 5.83317 9.99935 5.83317"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
