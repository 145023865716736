import { Box, Typography } from '@mui/material'
import { Role, RoleId } from 'api'

type Props = Readonly<{
  role: Role
}>

const roleColorsRecord: Record<
  RoleId,
  {
    text: string
    background: string
  }
> = {
  OWNER: {
    background: 'primary.main',
    text: 'white',
  },
  HR_MANAGER: {
    background: 'mediumPurple.main',
    text: 'white',
  },
  RECRUITER: {
    background: 'selectiveYellow.main',
    text: 'white',
  },
  INTERVIEWER: {
    background: 'cerulean.main',
    text: 'white',
  },
  HIRING_MANAGER: {
    background: 'flushOrange.main',
    text: 'white',
  },
  UNCONFIRMED_USER: {
    background: 'navyBlue.main',
    text: 'white',
  },
  UNCOMPANY_USER: {
    background: 'jewel.main',
    text: 'white',
  },
}

export const UserRoleBadge = ({ role }: Props) => {
  const roleColors = roleColorsRecord[role.code]

  return (
    <Box
      sx={{
        width: 'fit-content',
        bgcolor: roleColors.background,
        padding: '4px 6px',
        borderRadius: '4px',
      }}
    >
      <Typography color={roleColors.text}>{role.name}</Typography>
    </Box>
  )
}
