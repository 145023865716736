import { Box, InputAdornment, useTheme } from '@mui/material'
import { IconCalendar } from 'assets/icons'

import { InputBase } from '../common'

type Props = {
  value?: string
  onClick: () => void
  placeholder?: string
}

export const DateRangeInput = ({ value, onClick, placeholder }: Props) => {
  const theme = useTheme()

  return (
    <Box>
      <InputBase
        readOnly
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          onClick()
        }}
        placeholder={placeholder}
        value={value ?? ''}
        endAdornment={
          <InputAdornment position="end">
            <IconCalendar
              sx={{ fontSize: '16px', color: theme.palette.mineShaft[800] }}
            />
          </InputAdornment>
        }
      />
    </Box>
  )
}
